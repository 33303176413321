// extracted by mini-css-extract-plugin
export var courseSubhead = "ReviewsGridFeatureCard-module--course-subhead--vYFDF";
export var headshotContainer = "ReviewsGridFeatureCard-module--headshot-container--YW5Cf";
export var name = "ReviewsGridFeatureCard-module--name--kKQg1";
export var noHeadshot = "ReviewsGridFeatureCard-module--no-headshot--v4rVT";
export var quotation = "ReviewsGridFeatureCard-module--quotation--C8s1Z";
export var reviewCardFeature = "ReviewsGridFeatureCard-module--review-card-feature--aiSoA";
export var reviewContent = "ReviewsGridFeatureCard-module--review-content--eYozs";
export var reviewerCareer = "ReviewsGridFeatureCard-module--reviewer-career--CIlnS";
export var reviewerCareerDetails = "ReviewsGridFeatureCard-module--reviewer-career-details--cVlVv";
export var reviewerContainer = "ReviewsGridFeatureCard-module--reviewer-container--dXZfZ";
export var reviewerCourses = "ReviewsGridFeatureCard-module--reviewer-courses--cXRV+";
export var reviewerName = "ReviewsGridFeatureCard-module--reviewer-name--8S8xs";
export var stars = "ReviewsGridFeatureCard-module--stars--SJqO0";
export var studentJourney = "ReviewsGridFeatureCard-module--student-journey--lMDoj";
// extracted by mini-css-extract-plugin
export var courseSubhead = "ReviewsGridSmallCard-module--course-subhead--p93sN";
export var head = "ReviewsGridSmallCard-module--head--yAD7R";
export var name = "ReviewsGridSmallCard-module--name--E7bPg";
export var quotation = "ReviewsGridSmallCard-module--quotation--0aeR9";
export var reviewCard = "ReviewsGridSmallCard-module--review-card--Q3U2s";
export var reviewerCareer = "ReviewsGridSmallCard-module--reviewer-career--Ukdkd";
export var reviewerCareerDetails = "ReviewsGridSmallCard-module--reviewer-career-details--1bzAv";
export var reviewerContainer = "ReviewsGridSmallCard-module--reviewer-container--xnJu1";
export var reviewerCourses = "ReviewsGridSmallCard-module--reviewer-courses--a5idq";
export var reviewerHeadshot = "ReviewsGridSmallCard-module--reviewer-headshot--cxomI";
export var reviewerName = "ReviewsGridSmallCard-module--reviewer-name--gITRd";